import React, { useEffect } from 'react';

function WhatsAppSignupPage() {
  useEffect(() => {
    // Load the Facebook SDK script
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    // Initialize the SDK after it loads
    window.fbAsyncInit = function() {
      window.FB.init({
        appId     : '674154064702428', 
        cookie     : true,
        xfbml      : true,
        version    : 'v21.0'
      });
    };
  }, []); 

  const checkLoginState = () => {
    window.FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
    });
  };

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      console.log('Welcome! Fetching your information.... ');
      window.FB.api('/me', function(response) {
        console.log('Good to see you, ' + response.name + '.');
        // Handle the response and sign up the user
      });
    } else {
      console.log('User cancelled login or did not fully authorize.');
    }
  };

  return (
    <div>
      <h1>Sign Up to Facebook via WhatsApp</h1>
      <div
        className="fb-login-button"
        data-width=""
        data-size="large"
        data-button-type="login_with"
        data-layout="default"
        data-auto-logout-link="false"
        data-use-continue-as="false"
        data-onlogin="checkLoginState();"
        data-config_id="846794963913363" // Replace with your actual config_id
      ></div>
    </div>
  );
}

export default WhatsAppSignupPage;