// AppBar.js
import React from 'react';
import { Link } from 'react-router-dom';
import uniLogo from '../img/uni-logo.png'; // Add import for logo

const AppBar = () => {
  return (
    <div className="fixed top-8 left-1/2 -translate-x-1/2 z-50">
      <div className="flex items-center justify-between bg-white/80 backdrop-blur-md px-6 py-3 rounded-full shadow-lg border border-gray-100 w-[320px] md:w-[400px]">
        {/* Logo Section */}
        <div className="flex items-center gap-2">
          <Link to="/">
            <img 
              src={uniLogo} 
              alt="Uni AI Logo" 
              className="w-10 h-10 rounded-full object-cover shadow-lg cursor-pointer"
            />
          </Link>
          <span className="font-semibold text-lg">Uni AI</span>
        </div>

        {/* Get Started Button */}
        <button className="px-6 py-2 bg-purple-600 text-white rounded-full font-medium hover:bg-purple-700 transition-colors">
          Get Started
        </button>
      </div>
    </div>
  );
};

export default AppBar;