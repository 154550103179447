import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { FiArrowUpRight } from 'react-icons/fi';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types';

import ProductBanner from '../components/responsiveProductBanner';
import ContactUs from '../components/contactUs';
import AppBar from '../components/whatsappStoreComponents/appBar';
import Pricing from '../components/Pricing';
import frontGif from '../components/img/Minimal Gradient Modern Animated Marketing Instagram Reel (3).gif';

const PADDING = 12;
const SCROLL_OFFSET = ['end end', 'end start'];
const OPACITY_KEYFRAMES = [0.25, 0.5, 0.75];
const OPACITY_VALUES = [0, 1, 0];
const SCALE_KEYFRAMES = [0, 1];
const SCALE_VALUES = [1, 0.85];
const Y_TRANSFORM = [250, -250];

const ParallaxImage = ({ imgUrl }) => {
  const imageRef = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: imageRef,
    offset: SCROLL_OFFSET,
  });

  const scale = useTransform(scrollYProgress, SCALE_KEYFRAMES, SCALE_VALUES);
  const opacity = useTransform(scrollYProgress, SCALE_KEYFRAMES, [1, 0]);

  return (
    <motion.div
      ref={imageRef}
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: `calc(100vh - ${PADDING * 2}px)`,
        top: PADDING,
        scale,
      }}
      className="sticky z-0 overflow-hidden rounded-3xl md:blur-sm blur-[2px]"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/50"
        style={{ opacity }}
      />
    </motion.div>
  );
};

const GetStartedButton = ({ text, className = "" }) => (
  <button 
    className={`rounded-full bg-gradient-to-r from-purple-600 to-blue-600 
                px-8 py-3 text-lg font-semibold text-white 
                transition-all hover:opacity-90 hover:scale-105 
                focus:outline-none focus:ring-2 focus:ring-purple-600 
                shadow-lg ${className}`}
  >
    {text} <FiArrowUpRight className="inline ml-1" />
  </button>
);

const ParallaxHeading = ({ subheading, heading, buttonText }) => {
  const headingRef = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: headingRef,
    offset: ['start end', 'end start'],
  });

  const y = useTransform(scrollYProgress, SCALE_KEYFRAMES, Y_TRANSFORM);
  const opacity = useTransform(scrollYProgress, OPACITY_KEYFRAMES, OPACITY_VALUES);

  const [mainText, highlightText] = heading.split('with ');

  return (
    <motion.div
      ref={headingRef}
      style={{ y, opacity }}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white px-8 md:px-16"
    >
      {subheading && (
        <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl max-w-4xl">
          {subheading}
        </p>
      )}
      <div className="text-center text-4xl font-bold md:text-7xl max-w-4xl mx-auto px-4 mb-8">
        <span>{mainText}with </span>
        <HighlightedText text={highlightText} />
      </div>
      <GetStartedButton text={buttonText || "Get Started"} className="mt-8" />
    </motion.div>
  );
};

const HighlightedText = ({ text }) => (
  <span className="relative inline-block">
    <span className="relative z-10">{text}</span>
    <span 
      className="absolute inset-0 bg-gradient-to-r from-purple-600/40 to-blue-600/40 
                 -skew-y-2 transform rounded-lg -rotate-1
                 -left-2 -right-2 top-0 bottom-0"
      aria-hidden="true"
    />
  </span>
);

const ContentSection = ({ language = 'he' }) => {
  const content = {
    en: {
      title: "Take Your Business to the Next Level with a WhatsApp Store",
      buttonText: "Start Your Journey",
      description: "Uni is an AI-powered solution that transforms your WhatsApp into a complete digital storefront. Our smart technology automatically handles customer inquiries, takes orders, and manages payments - all through WhatsApp, where your customers already are."
    },
    he: {
      title: "קח את העסק שלך לשלב הבא עם חנות וואטסאפ",
      buttonText: "התחל את המסע",
      description: "Uni הוא פתרון מבוסס בינה מלאכותית שהופך את הוואטסאפ שלך לחזית דיגיטלית מלאה. הטכנולוגיה החכמה שלנו מטפלת אוטומטית בפניות לקוחות, מקבלת הזמנות ומנהלת תשלומים - הכל דרך וואטסאפ, היכן שהלקוחות שלך כבר נמצאים."
    }
  };

  return (
    <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-12 pt-12 md:grid-cols-12"> {/* Changed pb-24 to pb-12 */}
      <div className="col-span-1 flex flex-col space-y-4 md:col-span-4">
        <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600" 
            style={{ direction: language === 'he' ? 'rtl' : 'ltr' }}>
          {content[language].title}
        </h2>
        <GetStartedButton text={content[language].buttonText} />
      </div>
      <div className="col-span-1 md:col-span-8">
        <p className="mb-4 text-xl text-neutral-600 md:text-2xl"
           style={{ direction: language === 'he' ? 'rtl' : 'ltr' }}>
          {content[language].description}
        </p>
      </div>
    </div>
  );
};

ContentSection.propTypes = {
  language: PropTypes.oneOf(['en', 'he']).isRequired
};

// 1. Update ParallaxSection to handle missing data
const ParallaxSection = ({ imgUrl, subheading, heading, children, language = 'en' }) => {
  if (!imgUrl || !heading) {
    return null; // Or render a fallback UI
  }

  return (
    <div style={{ padding: PADDING }}>
      <div className="relative h-[100vh]"> {/* Changed from h-[150vh] */}
        <ParallaxImage imgUrl={imgUrl} />
        <ParallaxHeading heading={heading} subheading={subheading} />
      </div>
      {children && React.cloneElement(children, { language })}
    </div>
  );
};

ParallaxSection.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  children: PropTypes.node,
  language: PropTypes.oneOf(['en', 'he'])
};

// 2. Update LandingPage component to ensure language prop is always defined
const LandingPage = ({ language = 'he' }) => {
  return (
    <div className="bg-white">
      <AppBar />
      <ParallaxSection
        imgUrl="https://pfst.cf2.poecdn.net/base/image/e5ef76d14e4a989caefb45cd23b2e8cb0836b81fb26a2f512c30ea07b6ff8a6b?w=1024&h=768&pmaid=203209294"
        heading="Boost Your Business to the Moon with AI-Powered WhatsApp store"
        language={language}
      >
        <ContentSection />
      </ParallaxSection>
      <div className="-mt-24"> {/* Add negative margin */}
        <ProductBanner language={language} frontGif={frontGif} />
      </div>
      <Pricing language={language} />
      <Divider />
      <ContactUs language={language} />
    </div>
  );
};

GetStartedButton.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
};

ParallaxHeading.propTypes = {
  subheading: PropTypes.string,
  heading: PropTypes.string.isRequired,
  buttonText: PropTypes.string
};

export default LandingPage;