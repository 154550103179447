import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './pages/LandingPage';
import DemoStorePage from './pages/DemoPage';
import BusinessDetailsPage from './pages/BusinessDetailsPage';
import WhatsAppSignupPage from './pages/WhatsAppSignupPage';
import TextParallaxContentExample from './pages/whatsapp_store';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/demo" element={<DemoStorePage />} />
        <Route path="/business-details" element={<BusinessDetailsPage />} />
        <Route path="/whatsapp-signup" element={<WhatsAppSignupPage />} />
        <Route path="/whatsapp-store" element={<TextParallaxContentExample />} />
      </Routes>
    </Router>
  );
}

export default App;