import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  Snackbar,
  Alert,
} from '@mui/material';

const translations = {
  en: {
    title: "Let's Stay Connected",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In convallis tortor eros. Donec vitae tortor lacus. Phasellus aliquam ante in maximus.",
    name: "Enter your name",
    company: "Company (optional)",
    email: "Enter Your email",
    phone: "Enter your Phone Number",
    message: "Tell us about yourself",
    terms: "By clicking contact us button, you agree to our",
    termsLink: "terms and policy",
    submitButton: "Contact Us",
    successMessage: (name, email) => `Thank you, ${name}! Your message has been successfully submitted. We'll get back to you shortly at ${email}.`,
    errorMessage: "We apologize, but there was an error submitting your form. Please try again or contact us directly.",
    validationError: "Please fill in all required fields.",
    errorInName: 'Name is required',
    errorInEmail: 'Email is required',
    errorInPhone: 'Phone-number is required',
    errorInMessage: 'Message is required'
  },
  he: {
    title: "יש לכם שאלה?",
    subtitle: "שלחו לנו הודעה ויצור קשר עמכם בהקדם",
    name: "הכנס את שמך",
    company: "חברה (אופציונלי)",
    email: "הכנס את האימייל שלך",
    phone: "הכנס את מספר הטלפון שלך",
    message: "ספר לנו על עצמך",
    terms: "בלחיצה על כפתור צור קשר, אתה מסכים ל",
    termsLink: "תנאים ומדיניות",
    submitButton: "צור קשר",
    successMessage: (name, email) => `תודה, ${name}! ההודעה שלך נשלחה בהצלחה. נחזור אליך בקרוב ל${email}.`,
    errorMessage: "אנו מתנצלים, אך הייתה שגיאה בשליחת הטופס. אנא נסה שוב או צור איתנו קשר ישירות.",
    validationError: "אנא מלא את כל השדות הנדרשים.",
    errorInName: 'השם הוא שדה חובה',
    errorInEmail: 'אימייל הוא שדה חובה',
    errorInPhone: 'מספר טלפון הוא שדה חובה',
    errorInMessage: 'הודעה הוא שדה חובה'
  },
};

export default function ContactUs({ language = 'en' }) {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const t = translations[language];
  const isRTL = language === 'he';

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    // Clear error when user starts typing
    if (errors[e.target.id]) {
      setErrors({ ...errors, [e.target.id]: '' });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = t.errorInName;
    if (!formData.email.trim()) newErrors.email = t.errorInEmail;
    if (!formData.phone.trim()) newErrors.phone = t.errorInPhone;
    if (!formData.message.trim()) newErrors.message = t.errorInMessage;
    
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email.trim() && !emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setSnackbarMessage(t.validationError);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
  
    const scriptURL = 'https://script.google.com/macros/s/AKfycbx4kYz8E67EUPQXGYtUnYV2KLZFi5roU4KWqW36Odxj5bBDbSqhC_tjx-wGYS9vxlWY/exec';
  
    try {
      await fetch(scriptURL, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'no-cors',
      });
  
      setSnackbarMessage(t.successMessage(formData.name, formData.email));
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setFormData({ name: '', company: '', email: '', phone: '', message: '' });
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage(t.errorMessage);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box
      id="contactUs"
      sx={{
        bgcolor: 'background.paper',
        pt: 8,
        pb: 6,
        direction: isRTL ? 'rtl' : 'ltr'
      }}
    >
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          fontWeight="bold"
          sx={{ direction: isRTL ? 'rtl' : 'ltr', textAlign: 'center' }}
        >
          {t.title}
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary" paragraph sx={{ direction: isRTL ? 'rtl' : 'ltr', textAlign: 'center' }}>
          {t.subtitle}
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, direction: isRTL ? 'rtl' : 'ltr', textAlign: 'center' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="name"
                label={t.name}
                placeholder={t.name}
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                required
                error={!!errors.name}
                helperText={errors.name}
                InputProps={{
                  sx: {
                    textAlign: isRTL ? 'right !important' : 'left',
                    direction: isRTL ? 'rtl' : 'ltr'
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: isRTL ? 'auto' : 0,
                    right: isRTL ? '20px' : 'auto',
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: isRTL ? 'right !important' : 'left',
                    direction: isRTL ? 'rtl' : 'ltr'
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="company"
                label={t.company}
                placeholder={t.company}
                variant="outlined"
                value={formData.company}
                onChange={handleChange}
                InputProps={{
                  sx: {
                    textAlign: isRTL ? 'right !important' : 'left',
                    direction: isRTL ? 'rtl' : 'ltr'
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: isRTL ? 'auto' : 0,
                    right: isRTL ? '20px' : 'auto',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="email"
                label={t.email}
                placeholder={t.email}
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                required
                error={!!errors.email}
                helperText={errors.email}
                type="email"
                InputProps={{
                  sx: {
                    textAlign: isRTL ? 'right !important' : 'left',
                    direction: isRTL ? 'rtl' : 'ltr'
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: isRTL ? 'auto' : 0,
                    right: isRTL ? '20px' : 'auto',
                  },
                  
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: isRTL ? 'right !important' : 'left',
                    direction: isRTL ? 'rtl' : 'ltr'
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="phone"
                label={t.phone}
                placeholder={t.phone}
                variant="outlined"
                value={formData.phone}
                onChange={handleChange}
                error={!!errors.phone}
                helperText={errors.phone}
                InputProps={{
                  sx: {
                    textAlign: isRTL ? 'right !important' : 'left',
                    direction: isRTL ? 'rtl' : 'ltr'
                  },
                }}
                InputLabelProps={{
                  sx: {
                    left: isRTL ? 'auto' : 0,
                    right: isRTL ? '20px' : 'auto',
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: isRTL ? 'right !important' : 'left',
                    direction: isRTL ? 'rtl' : 'ltr'
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
              fullWidth
              id="message"
              label={t.message}
              placeholder={t.message}
              multiline
              rows={4}
              variant="outlined"
              value={formData.message}
              onChange={handleChange}
              required
              error={!!errors.message}
              helperText={errors.message}
              InputProps={{
                sx: {
                  textAlign: isRTL ? 'right !important' : 'left',
                  direction: isRTL ? 'rtl' : 'ltr'
                },
              }}
              InputLabelProps={{
                sx: {
                  left: isRTL ? 'auto' : 0,
                  right: isRTL ? '20px' : 'auto',
                },
              }}
              FormHelperTextProps={{
                sx: {
                  textAlign: isRTL ? 'right !important' : 'left',
                  direction: isRTL ? 'rtl' : 'ltr'
                },
              }}
            />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary" sx={{ direction: isRTL ? 'rtl' : 'ltr' }}>
              {t.terms}{' '}
              <Link href="#" color="primary">
                {t.termsLink}
              </Link>
              ,
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="medium"
              sx={{ minWidth: 200 }}
            >
              {t.submitButton}
            </Button>
          </Box>
        </Box>
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}